import logo from "../../../assets/img/logo.png"
import arrowback from "../../../assets/img/arrow-back-outline-white.svg"
import downarrow from "../../../assets/img/down-arrow-icon.svg"
import menuIcon from "../../../assets/img/menu-icon.png"
import winnder1 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_001.jpg";
import winnder2 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_002.jpg";
import winnder3 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_003.jpg";
import winnder4 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_004.jpg";
import winnder5 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_005.jpg";
import winnder6 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_006.jpg";
import { NavLink } from "react-router-dom";
import winnder7 from "../../../assets/img/Kolkata-Durga-Puja-2024/JPG_007.jpg";

import zoomPic from "../../../assets/img/zoom-in-icon.svg"
import closeIcon from "../../../assets/img/close-white-icon.svg"
import { PandalPopup } from "../../../components/pandalDetailsPopup/pandalPopup";
import { useState } from "react";


export default function KDP2024KOLKATA() {
    const [open,setIsOpen] = useState(false);
    const [pandal,setPandal] = useState();

    const openPopup = (title,desc,theme,img)=>{
        setIsOpen(true);
        setPandal({title:title,desc:desc,theme:theme,img});
    }
    

    const toggleFilterMenu=()=>{
        let menubtn = document.querySelector('.down');
        menubtn.classList.toggle("up");
        let parent = document.querySelector('.UT7-gallery-filter-list');
        parent.classList.toggle("mobileshow");
    }
  return (
    <>
    <div class="UT7-body-area">
    <div class="container-fluid">      

    <div class="UT7-gallery-body-section">

        <div class="UT7-gallery-filter-box">
            <div class="UT7-gallery-filter-hdng">
            <NavLink to="/gallery"><a class="back"><img src={arrowback} /></a> </NavLink>
                GALLERY & ARCHIVES
                <span class="down" onClick={toggleFilterMenu}><img src={downarrow}/></span>
            </div>
            <ul class="UT7-gallery-filter-list">
            <li class="back"> <NavLink to="/gallery"> <img src={arrowback} /><span>Back To Gallery</span> </NavLink> </li>

                <li class="active"> <NavLink to="/Kolkata-Durga-Puja-2024"> <img src={menuIcon} /><span>Kolkata Durga Puja 2024</span> </NavLink> </li>
                <li > <NavLink to="/Bengal-Durga-Puja-2024"> <img src={menuIcon} /><span>Bengal Durga Puja 2024</span></NavLink> </li>
              
                {/* <li class="active" > <a><img src={menuIcon} /><span>Tripura Durga Puja 2022</span></a> </li> */}
            </ul>
        </div>

        <div class="UT7-gallery-cards-area">
            <div class="row">

                <div class="col-xxl-12">
                    <h3 class="UT7-gallery-page-title">Kolkata Durga Puja 2024</h3>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder1} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Ajeya Sanghati',"The eternal eye shapes our perception, alternating between knowledge and ignorance, like the phases of the moon and mind. Fullness brings wisdom; waning brings darkness before renewal. Chandradhar Dev’s scattered vision sees Manasa with both hatred and divinity. When his sight becomes whole, he perceives her as eternal and non-dual—this is the true essence of vision.", 'IKKHON; Pandal Architect : Deep Das & Eshika Chandra', winnder1);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Aikatan Juba Sanstha" description="Commemorates Ma Durga's victory over COVID-19,who is portrayed as Mahishasur.The theme is conveyed through the spectacular lights and sound shows." theme="Asho Maa" img={winnder1} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder2} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Yuba Samaj',"Pays homage to the Bengali warriors and freedom fighters who helped bring about this glorious occasion of 75 years of independence.The theme is personified through the use of independence-themed lights and sound shows.", 'Azadi ka Amrit Mahotsav', winnder2);

                            openPopup('Hatibagan Sarbojanin Durgotsav Committee',"The city's colonial architecture reflects its geographical, social, and cultural heritage. However, globalisation and urban development are homogenising its distinct features. While development is crucial, preserving the evolution of traditional architectural styles remains aesthetically important. This presentation is a draft diagram of that process.", 'THE PROCESS; Pandal Architect : Susanta Shibani Paul', winnder2);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Yuba Samaj" description="Pays homage to the Bengali warriors and freedom fighters who helped bring about this glorious occasion of 75 years of independence.The theme is personified through the use of independence-themed lights and sound shows." theme="Azadi ka Amrit Mahotsav" img={winnder2} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder3} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Ramthakur Sangha',"Is a replica of Lord Shiva's 2000-years-old Mandir,which is thought to be the first of the 12 jyotir linga shrines.", 'Gujarat Somnath Mandir', winnder3);
                            openPopup('Kestopur Prafulla Kanan (Poschim) Adhibasi Brinda',"The panchabhuta—water, fire, earth, and space—form 50 parts of the body, with the third eye as the hidden 51st. Vishnu divided Sati's body into 51 pieces, creating the 51 Sati Peeths. \"Ekanna\" (51) also symbolises unity, once representing joint families. Let us now live together in harmony, celebrating Durga Maa on earth.", 'EKANNO (50 + 1 = 51); Pandal Architect : Rupchand Kundu', winnder3);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Ramthakur Sangha" description="Is a replica of Lord Shiva's 2000-years-old Mandir,which is thought to be the first of the 12 jyotir linga shrines." theme="Gujarat Somnath Mandir" img={winnder3} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder4} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Sanghati Club',"Promotes a pollution free environment for the benefit of humanity and future generations.", 'Shopno Uran', winnder4);
                            openPopup('Naktala Udayan Sangha',"The theme “Ekannoborti” reflects the essence of family life during the 60s and 70s, when joint families were the norm. Back then, multiple generations lived together under one roof, sharing responsibilities, resources, and values. However, in today's world, joint families have gradually given way to nuclear families due to social and economic changes. This shift has redefined how we experience family, but the spirit of togetherness remains central to “Ekannoborti”, reminding us of the bond that unites us all.", 'EKANNOBORTI; Pandal Architect : Rintu Das', winnder4);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Sanghati Club" description="Promotes a pollution free environment for the benefit of humanity and future generations." theme="Shopno Uran" img={winnder4} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder5} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Azah Hind Sangha',"Highlights the culture,food and life of jarawas tribe of Andaman.The theme represented by the use of over 30 materials gathered from the jungle where the tribes resides.", 'Amal Hazra', winnder5);
                            openPopup('Santoshpur Trikon Park Sarbojanin Durgotsab Puja Committee',"This year, Santoshpur Trikon Park Durgotsab celebrates its 75th year, marking a long journey of bonding Durga Puja with public relations, industry, art, and technology. Over the decades, we have worked hard to maintain a unique cultural atmosphere and uphold our tradition. Our theme this year is “UDJAPAN – The Celebration”, aligning with the 75th year of both our Puja and the Indian Constitution. The Constitution, adorned with Indian art, represents ideals of human rights, justice, and equality for all. We aim to highlight its artistic beauty as part of our celebration.", 'UDJAPAN; Pandal Architect : Asim Paul', winnder5);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Azah Hind Sangha" description="Highlights the culture,food and life of jarawas tribe of Andaman.The theme represented by the use of over 30 materials gathered from the jungle where the tribes resides." theme="Amal Hazra" img={winnder5} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder6} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Netaji Play Forum Center',"Is a replica of largest temple in India built in the remembrance of Lord Ram with a twist.", 'Ayodhya Ram Mandir', winnder6);
                            openPopup('Tala Prattoy',"The search for Durga in my art practice is leading me toward an infinite horizon, where Durga transcends her physical form and becomes a formless essence—an eternal sense of existence. This year, Tala Prattoy presents this journey into the void, where Durga is not bound by image but exists as boundless energy. Through this theme, we invite everyone to explore the divine beyond form, embracing the infinite and the spiritual connection that Durga represents.", 'BIHIN - THE VOID; Pandal Architect : Susanta Shibani Paul', winnder6);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Netaji Play Forum Center" description="Is a replica of largest temple in India built in the remembrance of Lord Ram with a twist." theme="Ayodhya Ram Mandir" img={winnder6} /> */}
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder7} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Nabadiganta',"Depicts the richness of handcrafted materials by utilising locally sourced Bamboo, Clothing and crafted materials among other things.The theme is also represented by the use of sound and light shows,in which the spiritual Omkar echoed to create a peaceful atmosphere.", 'Kalpaloke', winnder7);
                            openPopup('Telengabagan Sarbojanin Durgotsab Committee',"Of all the major issues facing humanity today, the stark contrast between abundance and scarcity is perhaps the most relevant. In a world of 700 crore people, over 100 crore worth of food is wasted daily, while millions go hungry. Social events and gatherings celebrate excess, while many can only hope for a taste of food. The irony of wasting food in trendy restaurants or religious festivals is alarming, yet those same festivals can also feed the hungry. The choice is ours—let's strive to provide a simple meal for all. With fewer hungry souls, even this prosaic world may reveal its rhythm and beauty.", 'PRITHIBI GODYOMOY; Pandal Architect : Parimal Paul', winnder7);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Nabadiganta " description="Depicts the richness of handcrafted materials by utilising locally sourced Bamboo, Clothing and crafted materials among other things.The theme is also represented by the use of sound and light shows,in which the spiritual Omkar echoed to create a peaceful atmosphere" theme="Kalpaloke" img={winnder7} /> */}
                    </div>
                </div>
                <PandalPopup open={open} title={pandal?.title} description={pandal?.desc} theme={pandal?.theme} img={pandal?.img} close={()=>{ setIsOpen(false)}} />

            </div>
        </div>

    </div>

    </div>
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
  )
}
