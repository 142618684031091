import { useEffect, useState } from "react";
import { useRef } from "react";
import axios from 'axios';
import { useNavigate, useHistory, unstable_HistoryRouter } from "react-router-dom";

import { enviromentConfigs } from "../../env/enviroment-staging";

import Loader from "../../components/loader/loader";
import questionmark from '../../assets/img/question-mark-icon.svg';
import MediaButton from "../../components/media-button/MediaButton";
import closedBanner from "../../assets/img/web_closed_banner.jpg";
import newReg from "../../assets/registration/assigment.png";
import oldReg from "../../assets/registration/application.png";
import './form.css'

function cleanAndSendToDB(inputString) {
    // Use a regular expression to match and remove special characters
    const cleanedString = inputString.replace(/[^\w\s\n]+/g, '');
  
    // Send the cleaned string to the database (replace this with your actual database code)
    // For demonstration, we'll just log the cleaned string here
    console.log("Cleaned String:", cleanedString);
    return cleanedString
  }
  
  
  
  

function FormPage() {

    // return ShowSelection();
    return PandalRegistration();
}

function ShowSelection() {
    // const [newUser,setNewUser] = useState(false)
    const navigate = useNavigate();
    
    const navigateToFrom = ()=>{
        navigate('/new-registration')
    }

    const navigateToHistory = ()=>{
        navigate('/see-history')
    }

    return (<>
        <div class="UT7-form-body-area">
        <div class="UT7-form-box UT7-form-box-selection">
            <h4 class="UT7-form-box-hdng">Lets Start!</h4>
            <div class="UT7-form-wrap">
                <div class="row">
    
                    <div className="col-lg-6">
                            <div class="container mt-5 text-center">
                                <div class="card shadow-lg p-3 mb-5 bg-white rounded">
                                    <div class="card-body text-center">
                                        <img src={newReg} height={160} className="UT7-selection-logo"/>
                                        <h4 class="card-title">New Registration</h4>
                                        {/* <p class="card-text">
                                            This is a simple card with a shadow effect. You can add more content here as needed.
                                        </p> */}
                                        <button onClick={navigateToFrom} class="btn btn-warning text-center">Register Now!</button>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="col-lg-6">
                            <div class="container mt-5 ">
                                <div class="card shadow-lg p-3 mb-5 bg-white rounded">
                                    <div class="card-body text-center">
                                        <img src={oldReg} height={160} className="UT7-selection-logo"/>
                                        <h5 class="card-title">Already Registerd!</h5>
                                        {/* <p class="card-text">
                                            This is a simple card with a shadow effect. You can add more content here as needed.
                                        </p> */}
                                        <button onClick={() => {
                                            navigateToHistory()
                                        }} class="btn btn-warning text-center">Check Registration</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            {/* <div class="UT7-form-action-row">
                <button class="UT7-register-btn submit-btn">SUBMIT</button>
            </div> */}
        </div>
    
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
        </>)
}


function PandalRegistration() {

    const [showSpinner,setShowSpinner] = useState(false)
    const [formValue, setFormValue] = useState('');
    const [pandal,setPandal] = useState({
    "nameOfORG": "",
    "numberOfORG": "",
    "name_of_pandal": "New Pandal Name",
    "address_of_pandal": "Some Real Address Here",
    "president_of_pandal": "Rony Roy",
    "president_phoneno": "9239492289",
    "secretary_of_pandal": "Arko Dey",
    "secretary_phoneno": "9239492289",
    "landmark_of_pandal": "Near Mars Park",
    "state_of_pandal":'',
    "district_of_pandal": "select",
    "sociallink_of_pandal": "https://example.com/pandal-social",
    "contact_one_name": "Riju Dutta",
    "contact_one_phone": "555-123-4567",
    "contact_one_email": "riju@example.com",
    "contact_two_name": "Smriti Dey",
    "contact_two_phone": "555-987-6543",
    "contact_two_email": "dey@example.com",
    "pandal_theme": "Theme name",
    "pandal_description": "A pandal celebrating durga puja.",
    "pandal_architech": "Architech name",
    "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
    "pandal_materials": [
        {id:1,checked:false,name:"BAMBOO"},
        {id:2,checked:false,name:"CLOTH"},
        {id:3,checked:false,name:"WOOD"}, 
        {id:4,checked:false,name:"IRON"}, 
        {id:5,checked:false,name:'PLY-WOOD'}
    ],
    "pandal_materials_others":"",
    "echofriendly_pandal": "",
    "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
    "pandal_amenities": [
        {id:1,checked:false,name: "Toilet"},
        {id:2,checked:false,name: "Entry / Exit"},
        {id:3,checked:false,name: "VIP Entry"},
        {id:4,checked:false,name: "Emergency"},
        {id:5,checked:false,name: "First Aid"},
        {id:6,checked:false,name: "Fire Extinguisher"},
        {id:7,checked:false,name: "Drinking Water"}
    ],
    "pandal_amenities_others":'',
    "pandal_facebook_link":"",
    "pandal_insta_link":"",
    "pandal_pincode":"",
    "pandal_files_link": "https://example.com/pandal-files",
    "pandal_of_ZONE":"KOL",
    });
    const [districts, setDistricts] = useState([]);
     const [districtsTri, setDistrictsTri] = useState([]);
    const [pandalUniqeID,setPandalUnique] = useState('');
    const pandalOptions = [{name:"BAMBOO"}, {name:"CLOTH"}, {name:"WOOD"}, {name:"IRON"}, {name:'PLY-WOOD'}];
    const closeRegistration = true; //? Change Registration Status Here
    useEffect(()=>{
        setDistricts( [
            // { "id": 1, "name": "TRIPURA" },
            { "id": 2, "name": "Alipurduar" },
            { "id": 3, "name": "Bankura" },
            { "id": 4, "name": "Birbhum" },
            { "id": 5, "name": "Cooch Behar" },
            { "id": 6, "name": "Dakshin Dinajpur" },
            { "id": 7, "name": "Darjeeling" },
            { "id": 8, "name": "Hooghly" },
            { "id": 9, "name": "Howrah" },
            { "id": 10, "name": "Jalpaiguri" },
            { "id": 11, "name": "Jhargram" },
            { "id": 12, "name": "Kalimpong" },
            { "id": 13, "name": "Kolkata" },
            { "id": 14, "name": "Malda" },
            { "id": 15, "name": "Murshidabad" },
            { "id": 16, "name": "Nadia" },
            { "id": 17, "name": "North 24 Parganas" },
            { "id": 18, "name": "Paschim Bardhaman" },
            { "id": 19, "name": "Paschim Medinipur" },
            { "id": 20, "name": "Purba Bardhaman" },
            { "id": 21, "name": "Purba Medinipur" },
            { "id": 22, "name": "Purulia" },
            { "id": 23, "name": "South 24 Parganas" },
            { "id": 24, "name": "Uttar Dinajpur" }
          ]);
        setDistrictsTri([{ "id": 2, "name": "Others" },]);


        setPandal({
            "nameOfORG": "",
            "numberOfORG": "",
            "name_of_pandal": "Pandal Name",
            "address_of_pandal": "Address Here",
            "president_of_pandal": "Name Here",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Name Here",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "NA(Not Available)",
            "state_of_pandal":'',
            "city_of_pandal": "select",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Name Here",
            "contact_one_phone": "9187738891",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Name Here",
            "contact_two_phone": "9187738891",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "",
            "pandal_description": "",
            "pandal_architech": "",
            "pandal_why_unique": "",
            "pandal_materials": [
                {id:1,checked:false,name:"BAMBOO"},
                {id:2,checked:false,name:"CLOTH"},
                {id:3,checked:false,name:"WOOD"}, 
                {id:4,checked:false,name:"IRON"}, 
                {id:5,checked:false,name:'PLY-WOOD'}
            ],
            "pandal_materials_others":"",
            "echofriendly_pandal": "",
            "pandal_structure_description": "",
            "pandal_amenities": [
                {id:1,checked:false,name: "Toilet"},
                {id:2,checked:false,name: "Entry / Exit"},
                {id:3,checked:false,name: "VIP Entry"},
                {id:4,checked:false,name: "Emergency"},
                {id:5,checked:false,name: "First Aid"},
                {id:6,checked:false,name: "Fire Extinguisher"},
                {id:7,checked:false,name: "Drinking Water"}
            ],
            "pandal_amenities_others":'',
            "pandal_facebook_link":"",
            "pandal_insta_link":"",
            "pandal_pincode":"",
            "pandal_files_link": "NA(Not Available)",
            "pandal_of_ZONE":"KOL",
            })
    },[]);
    const navigate = useNavigate();
    // const history = unstable_HistoryRouter();

    const handleChange = (event)=>{
        const { name, value} = event.target;
        setPandal((prevFormData) => ({ ...prevFormData, [name]:  (name === 'pandal_description' || name === 'pandal_why_unique' || name === 'pandal_structure_description' )?cleanAndSendToDB(value): value }));
    }


    const handleAmenitiesChange = (event,checkboxId)=>{
        const updatedCheckboxes = pandal?.pandal_amenities.map((checkbox) =>
        checkbox.id === checkboxId
            ? { ...checkbox, checked: event.target.checked }
            : checkbox
        );
        setPandal((prevData) => ({
            ...prevData,
            pandal_amenities: updatedCheckboxes
        }));
    }

    const handleMaterialsChange = (event,checkboxId)=>{
        const updatedCheckboxes = pandal.pandal_materials.map((checkbox) =>
        checkbox.id === checkboxId
            ? { ...checkbox, checked: event.target.checked }
            : checkbox
        );
        setPandal((prevData) => ({
            ...prevData,
            pandal_materials: updatedCheckboxes
        }));
    }

    const createPandal = (pandal)=>{
        console.log(pandal);
        if(pandal?.nameOfORG !=="" && pandal?.numberOfORG !=="" && pandal?.name_of_pandal !=="" && pandal?.president_of_pandal !=="" && pandal?.secretary_of_pandal !==""&& pandal?.district_of_pandal !=="select"&& pandal?.state_of_pandal !=="select" && ((pandal?.pandal_facebook_link !=="") || (pandal?.pandal_insta_link !=="")) && pandal?.contact_one_phone !=="" && pandal?.contact_two_name !=="" && pandal?.pandal_description !=="" && pandal?.pandal_why_unique !==""&& pandal?.echofriendly_pandal !=="" && pandal?.pandal_options_others !=="" && pandal?.address_of_pandal !==""&& pandal?.president_phoneno !==""&& pandal?.secretary_phoneno !=="" &&pandal?.pandal_pincode !==""&&pandal?.contact_one_name !==""&& pandal?.contact_one_email !=="" &&  pandal?.contact_two_phone !=="" &&pandal?.pandal_theme !==""&& pandal?.pandal_architech !==""&& pandal?.pandal_materials_others !==""&& pandal?.pandal_structure_description !==""&& pandal?.pandal_files_link !==""){
            console.log("Form VLUIDS");
            setFormValue('');
            // console.log(selectRef.current.value);
            // setPandal((prevFormData) => ({...prevFormData,district_of_pandal:selectRef.current.value}));
            // console.log(pandal);
            setShowSpinner(true);
            axios.post(enviromentConfigs?.REACT_APP_API_URL+'pandals/create',pandal)
            .then(response => {
                // Handle successful response
                console.log(response);
                console.log(response?.data?.data?.pandalId);
                localStorage.setItem('pandalUnique',response?.data?.data?.pandalId);
                setPandalUnique(response?.data?.pandalId)
                // history.push('/successpage', response?.data?.pandalId);
                navigate('/succespage');
                setShowSpinner(false);
                
            })
            .catch(error => {
                // Handle error
                console.log(error);
                navigate('/failedpage');
                setShowSpinner(false);
            });
        }else{
            alert('Field cannot be empty');
        }
       
    }

  return (
    
    <>
    <div class="UT7-body-area">
    <div class="container-fluid"> 
    {closeRegistration?
    
    <>
    <div class="UT7-form-body-area">
                          <div class="UT7-form-box clod-2">
                              {/* <h4 class="UT7-form-box-hdng">REGISTRATION C</h4> */}
                              <div class="UT7-form-wrap clod">
                                  {/* web_closed_banner.jpg */}
                                  <img src={closedBanner} className="closedBanner" height={100} />
                              </div>

                          </div>

    
    </div>
    {/* <div class="lft-bg"></div>
    <div class="rgt-bg"></div> */}
    </>

    :
    <>
    {showSpinner?<Loader/>:null}
        

    <div class="UT7-form-body-area">
        <div class="UT7-form-box">
            <h4 class="UT7-form-box-hdng">REGISTRATION FORM</h4>
                
            <div class="UT7-form-wrap">
                <div className="row">
                    <div className="col-12">
                        <h5 className="UT7-form-sub-heading"> Organization Details:- </h5>
                    </div>
                    <div className="col-6">
                        <div class="UT7-form-field-row">
                            <label class="UT7-form-title">Name Of Puja <span class="red-text">*</span> </label>
                            <div class="UT7-form-input-row">
                                <input type="text" value={pandal?.nameOfORG} onChange={handleChange} name="nameOfORG" class="UT7-form-input" placeholder="Your Name" />
                            </div>
                            {pandal?.nameOfORG !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                            {/* <span class="UT7-form-msg success">success message</span> */}
                        </div>
                    </div>

                    <div className="col-6">
                        <div class="UT7-form-field-row">
                            <label class="UT7-form-title">Contact Number <span class="red-text">*</span> 
                            
                            </label>
                            <div class="UT7-form-input-row">
                                <input type="number" value={pandal?.numberOfORG} onChange={handleChange} name="numberOfORG" class="UT7-form-input" placeholder="Your Number" />
                            </div>
                            {pandal?.numberOfORG !==""?null:<span class="UT7-form-msg error">Please enter a valid number</span>}
                            <span class="UT7-form-msg error"><strong><h6>*Note: By this number you can check filled details later!</h6></strong></span>
                            {/* <span class="UT7-form-msg success">success message</span> */}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div className="col-12">
                        <h5 className="UT7-form-sub-heading "> Pandal Details:- </h5>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Name Of Puja <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" value={pandal?.name_of_pandal} onChange={handleChange} name="name_of_pandal" class="UT7-form-input" placeholder="Your Name" />
                                    </div>
                                    {pandal?.name_of_pandal !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                    {/* <span class="UT7-form-msg success">success message</span> */}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Name of The Puja President <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="Name Of The President" value={pandal?.president_of_pandal} onChange={handleChange} name="president_of_pandal" />
                                    </div>
                                    {pandal?.president_of_pandal !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Name of the Secretary <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="" value={pandal?.secretary_of_pandal} onChange={handleChange} name="secretary_of_pandal" />
                                    </div>
                                    {pandal?.secretary_of_pandal !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">State <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                    <select  class="UT7-form-input" name="state_of_pandal" onChange={handleChange}  >
                                        <option value="select">Select State</option>
                                            <option value="west_bengal">West Bengal</option>
                                            {/* <option value="tripura">Tripura</option> */}
                                        </select>
                                    </div>
                                    {pandal?.state_of_pandal !=="select"?null:<span class="UT7-form-msg error">Needs to be selected</span>}
                                </div>
                            </div>
                            {pandal?.state_of_pandal === "west_bengal"?

                            <div class="col-lg-12">
                            <div class="UT7-form-field-row">
                                <label class="UT7-form-title">District <span class="red-text">*</span></label>
                                <div class="UT7-form-input-row">
                                    <select  class="UT7-form-input" name="district_of_pandal" onChange={handleChange}  >
                                        <option value="select">Select District</option>
                                        {districts?.map(element => {
                                            return (
                                            <>
                                            <option value={element?.name}>
                                            {element?.id ===1 || element?.id === 13? <b className="spcl-bold">{element?.name}</b>:element?.name }
                                            {/* <b>{element?.name}</b> */}
                                            </option>
                                            </>
                                            );
                                        })}
                                        
                                    </select>
                                </div>
                                {pandal?.district_of_pandal !=="select"?null:<span class="UT7-form-msg error">Needs to be selected</span>}
                            </div>
                            </div>
                            :
                            null
                            }

                            {pandal?.state_of_pandal === "tripura"?

                            <div class="col-lg-12">
                            <div class="UT7-form-field-row">
                                <label class="UT7-form-title">District <span class="red-text">*</span></label>
                                <div class="UT7-form-input-row">
                                    <select  class="UT7-form-input" name="district_of_pandal" onChange={handleChange}  >
                                        <option value="select">Select District</option>
                                        {districtsTri?.map(element => {
                                            return (
                                            <>
                                            <option value="others">
                                            {element?.id ===1 || element?.id === 13? <b className="spcl-bold">{element?.name}</b>:element?.name }
                                            {/* <b>{element?.name}</b> */}
                                            </option>
                                            </>
                                            );
                                        })}
                                        
                                    </select>
                                </div>
                                {pandal?.district_of_pandal !=="select"?null:<span class="UT7-form-msg error">Needs to be selected</span>}
                            </div>
                            </div>
                            :
                            null
                            }
                           
                            
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title"> Please share the link of the Puja Social Media Page (any one at least)<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input mb-2" placeholder="https://www.facebook.com/example" value={pandal?.pandal_facebook_link} onChange={handleChange} name="pandal_facebook_link" />
                                        <input type="text" class="UT7-form-input" placeholder="https://www.instagram.com/example" value={pandal?.pandal_insta_link} onChange={handleChange} name="pandal_insta_link" />
                                    </div>
                                    {(pandal?.pandal_facebook_link !=="") || (pandal?.pandal_insta_link !=="")?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title"> Contact Number of Contact Person 1<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="" value={pandal?.contact_one_phone} onChange={handleChange} name="contact_one_phone" />
                                    </div>
                                    {pandal?.contact_one_phone !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Contact Person 2 <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="" value={pandal?.contact_two_name} onChange={handleChange} name="contact_two_name" />
                                    </div>
                                    {pandal?.contact_two_name !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Email id of Contact Person 2<span class="red-text"></span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="email" class="UT7-form-input" placeholder="E-mail Address" value={pandal?.contact_two_email} onChange={handleChange} name="contact_two_email" />
                                    </div>
                                    {/* <span class="UT7-form-msg error">Please enter correct email address</span> */}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Explain the Puja Theme<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <textarea type="text" class="UT7-form-input" placeholder="Explain..." value={pandal?.pandal_description} onChange={handleChange} name="pandal_description" />
                                    </div>
                                    {pandal?.pandal_description !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Why do you think your Pandal is unique?<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <textarea type="text" class="UT7-form-input" placeholder="" value={pandal?.pandal_why_unique} onChange={handleChange} name="pandal_why_unique" />
                                    </div>
                                    {pandal?.pandal_why_unique !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Are all materials used Eco-Friendly?<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <select  class="UT7-form-input" name="echofriendly_pandal" onChange={handleChange}  >
                                        <option value="select">Select Material</option>
                                            <option value="no">No</option>
                                            <option value="yes">YES</option>
                                        </select>
                                    </div>
                                    {pandal?.echofriendly_pandal !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title"> Put tickmark on whatever is applicable to your Pandal<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row d-flex flex-column">
                                        {/* <input type="text" class="UT7-form-input" placeholder="E-mail Address" value={pandal?.pandal_materials} onChange={handleChange} name="pandal_materials" /> */}
                                    {pandal?.pandal_amenities?.map((amenities)=>{
                                        return(<>
                                        <label class="UT7-custom-checkbox" key={amenities?.id}>
                                        <input name={amenities?.name} type="checkbox" checked={amenities?.checked} onChange={(event) => handleAmenitiesChange(event, amenities?.id)}/>
                                        <span>{amenities?.name}</span>
                                        </label>
                                        </>)
                                    })}
                                    <label class="UT7-form-title">Others if any<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="Something..." value={pandal?.pandal_amenities_others} onChange={handleChange} name="pandal_amenities_others" />
                                    </div>
                                    </div>
                                    {pandal?.pandal_options_others !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="row">
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Complete Address of Puja Pandal <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="45/demo address,new road" value={pandal?.address_of_pandal} onChange={handleChange} name="address_of_pandal" />
                                    </div>
                                    {pandal?.address_of_pandal !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Contact Number of the President <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="number" class="UT7-form-input" placeholder="phone" value={pandal?.president_phoneno} onChange={handleChange} name="president_phoneno" />
                                    </div>
                                    {pandal?.president_phoneno !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Contact number of the Secretary <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="number" class="UT7-form-input" placeholder="" value={pandal?.secretary_phoneno} onChange={handleChange} name="secretary_phoneno" />
                                    </div>
                                    {pandal?.secretary_phoneno !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Pincode<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="number" class="UT7-form-input" placeholder="70080 or 700001" value={pandal?.pandal_pincode} onChange={handleChange} name="pandal_pincode" />
                                    </div>
                                    {pandal?.pandal_pincode !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title"> Contact Person 1<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="" value={pandal?.contact_one_name} onChange={handleChange} name="contact_one_name" />
                                    </div>
                                    {pandal?.contact_one_name !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Email id of Contact Person 1 <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="email" class="UT7-form-input" placeholder="E-mail Address" value={pandal?.contact_one_email} onChange={handleChange} name="contact_one_email" />
                                    </div>
                                    {pandal?.contact_one_email !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Contact Number of Contact Person 2 <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="number" class="UT7-form-input" placeholder="" value={pandal?.contact_two_phone} onChange={handleChange} name="contact_two_phone" />
                                    </div>
                                    {pandal?.contact_two_phone !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Puja Theme<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="" value={pandal?.pandal_theme} onChange={handleChange} name="pandal_theme" />
                                    </div>
                                    {pandal?.pandal_theme !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Architect Name<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="" value={pandal?.pandal_architech} onChange={handleChange} name="pandal_architech" />
                                    </div>
                                    {pandal?.pandal_architech !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title"> What materials have you used to build your Pandal?<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row d-flex flex-column">
                                        {/* <input type="text" class="UT7-form-input" placeholder="E-mail Address" value={pandal?.pandal_materials} onChange={handleChange} name="pandal_materials" /> */}
                                    {pandal?.pandal_materials?.map((materials)=>{
                                        return(<>
                                        <label class="UT7-custom-checkbox" key={materials?.id}>
                                        <input value={materials?.name} type="checkbox" checked={materials?.checked} onChange={(event) => handleMaterialsChange(event, materials?.id)} />
                                        <span>{materials?.name}</span>
                                        </label>
                                        </>)
                                    })}
                                    
                                    <label class="UT7-form-title">Others if any<span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="Something..." value={pandal?.pandal_materials_others} onChange={handleChange} name="pandal_materials_others" />
                                    </div>
                                    </div>
                                    {pandal?.pandal_materials_others !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">How have you made your Pandal structure strong? <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <textarea type="text" class="UT7-form-input" placeholder="" value={pandal?.pandal_structure_description} onChange={handleChange} name="pandal_structure_description" />
                                    </div>
                                    {pandal?.pandal_structure_description !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="UT7-form-field-row">
                                    <label class="UT7-form-title">Please add any relevant media files (Photos or Videos) for our jury to see <span class="red-text">*</span></label>
                                    <div class="UT7-form-input-row">
                                        <input type="text" class="UT7-form-input" placeholder="E-mail Address" value={pandal?.pandal_files_link} onChange={handleChange} name="pandal_files_link" />
                                    </div>
                                    {pandal?.pandal_files_link !==""?null:<span class="UT7-form-msg error">Field cannot be empty</span>}
                                    <label class="UT7-form-title note"><img src={questionmark} /><span>How to generate link</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    


                    {/* <div class="col-lg-6">
                        <div class="UT7-form-field-row">
                            <label class="UT7-form-title">Phone Number <span class="red-text">*</span></label>
                            <div class="UT7-form-input-row">
                                <input type="text" class="UT7-form-input" placeholder="Exp: +91 89877675" />
                            </div>
                        </div>
                    </div>

                   

                    <div class="col-lg-6">
                        <div class="UT7-form-field-row">
                            <label class="UT7-form-title">Landmark <span class="red-text">*</span></label>
                            <div class="UT7-form-input-row">
                                <input type="text" class="UT7-form-input" placeholder="Your nearest landmark" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="UT7-form-field-row">
                            <label class="UT7-custom-checkbox">
                                <input type="checkbox" />
                                <span>By submit you agree to our T&C and Privacy Policy</span>
                            </label>
                        </div>
                    </div> */}
                    
                </div>
            </div>
            <div class="UT7-form-action-row">
                <button class="UT7-register-btn submit-btn" onClick={()=>{createPandal(pandal);}}>SUBMIT</button>
            </div>
        </div>
    
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    <MediaButton/>
    </>
    }
    {/* <div class="lft-bg"></div>
    <div class="rgt-bg"></div> */}
    </div>
    </div>
    </>
  )
}

export {FormPage, ShowSelection};