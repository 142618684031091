import logo from "../../../assets/img/logo.png"
import arrowback from "../../../assets/img/arrow-back-outline-white.svg"
import downarrow from "../../../assets/img/down-arrow-icon.svg"
import menuIcon from "../../../assets/img/menu-icon.png"
import winnder1 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_001.jpg";
import winnder2 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_002.jpg";
import winnder3 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_003.jpg";
import winnder4 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_004.jpg";
import winnder5 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_005.jpg";
import winnder6 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_006.jpg";
import { NavLink } from "react-router-dom";
import winnder7 from "../../../assets/img/Bengal-Durga-Puja-2024/ROB_JPG_007.jpg";

import zoomPic from "../../../assets/img/zoom-in-icon.svg"
import closeIcon from "../../../assets/img/close-white-icon.svg"
import { PandalPopup } from "../../../components/pandalDetailsPopup/pandalPopup";
import { useState } from "react";


export default function ROB2024() {
    const [open,setIsOpen] = useState(false);
    const [pandal,setPandal] = useState();

    const openPopup = (title,desc,theme,img)=>{
        setIsOpen(true);
        setPandal({title:title,desc:desc,theme:theme,img});
    }
    

    const toggleFilterMenu=()=>{
        let menubtn = document.querySelector('.down');
        menubtn.classList.toggle("up");
        let parent = document.querySelector('.UT7-gallery-filter-list');
        parent.classList.toggle("mobileshow");
    }
  return (
    <>
    <div class="UT7-body-area">
    <div class="container-fluid">      

    <div class="UT7-gallery-body-section">

        <div class="UT7-gallery-filter-box">
            <div class="UT7-gallery-filter-hdng">
            <NavLink to="/gallery"><a class="back"><img src={arrowback} /></a> </NavLink>
                GALLERY & ARCHIVES
                <span class="down" onClick={toggleFilterMenu}><img src={downarrow}/></span>
            </div>
            <ul class="UT7-gallery-filter-list">
            <li class="back"> <NavLink to="/gallery"> <img src={arrowback} /><span>Back To Gallery</span> </NavLink> </li>

                <li > <NavLink to="/Kolkata-Durga-Puja-2024"> <img src={menuIcon} /><span>Kolkata Durga Puja 2024</span> </NavLink> </li>
                <li class="active"> <NavLink to="/Bengal-Durga-Puja-2024"> <img src={menuIcon} /><span>Bengal Durga Puja 2024</span></NavLink> </li>
              
                {/* <li class="active" > <a><img src={menuIcon} /><span>Tripura Durga Puja 2022</span></a> </li> */}
            </ul>
        </div>

        <div class="UT7-gallery-cards-area">
            <div class="row">

                <div class="col-xxl-12">
                    <h3 class="UT7-gallery-page-title">Bengal Durga Puja 2024</h3>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder1} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('2 no. Government colony Sarbojonin Durgotsab committee (Malda)',"", 'Suddho Suchi; Pandal Architect : Joy Chowdhury', winnder1);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder2} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Kadamtala Sarbojonin Durga Puja Committee(Jalpaiguri)',"", 'Sabekiana; Pandal Architect : Siyangshu Bisass', winnder2);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder3} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Kalyanpur Sarbojanin Durgapuja Committee (West Bardhaman)',"", 'Putul Nacher Itikotha; Pandal Architect : Dipanjan Pal', winnder3);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder4} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Panchanantala Sarbojonin Durgotsab Samity (Hoogly)',"", 'Biponnotar Utso Mukhe; Pandal Architect : Devdeep Pal & Sourav Ghosh', winnder4);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder5} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Salkia Sadharan Durga Puja Jatadhari Park (Howrah-Dankuni)',"", 'Salkolpo; Pandal Architect : Santanu Dey Bakshi', winnder5);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder6} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Subrata Sangha (Darjeeling)',"", 'Dristi; Pandal Architect : Biren Roy', winnder6);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder7} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Jambuni Sarbojanin Durga Puja Samity (Birbhum)',"", 'Matir Tane Mrinmoyi; Pandal Architect : Sandip Sadhu', winnder7);
                        }}><img src={zoomPic} /></div>
                    </div>
                </div>
                <PandalPopup open={open} title={pandal?.title} description={pandal?.desc} theme={pandal?.theme} img={pandal?.img} close={()=>{ setIsOpen(false)}} />

            </div>
        </div>

    </div>

    </div>
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
  )
}
