import { NavLink } from "react-router-dom";
import imgCardBG from "../../assets/img/image-card-bg-2.png"


function GalleryPage(){
    return (
    <>
    <div class="UT7-body-area">
        <div class="container-fluid">      

            <div class="UT7-gallery-body-section">

                <div class="UT7-gallery-cards-area">
                    <div class="row justify-content-center">

                        <div class="col-xxl-12">
                            <h3 class="UT7-gallery-page-title text-center mb-5">GALLERY & ARCHIVES</h3>
                        </div>
                        <div class="col-xxl-6">
                            <div class="row justify-content-center">
                                <div class="col-xxl-6 col-xl-4 col-lg-6 col-md-6">
                                    <NavLink to="/Kolkata-Durga-Puja-2020">
                                        <a  class="UT7-gallery-card year">
                                            <div class="UT7-gallery-card-cnt-overlay">
                                                <div class="UT7-gallery-card-cnt">
                                                    <div class="UT7-card-title">2020</div>
                                                </div>
                                            </div>
                                            <img src={imgCardBG} class="UT7-card-thumb" />
                                        </a>
                                    </NavLink>
                                </div>

                                <div class="col-xxl-6 col-xl-4 col-lg-6 col-md-6">
                                    <NavLink to="/Kolkata-Durga-Puja-2021">
                                    <a class="UT7-gallery-card year">
                                        <div class="UT7-gallery-card-cnt-overlay">
                                            <div class="UT7-gallery-card-cnt">
                                                <div class="UT7-card-title">2021</div>
                                            </div>
                                        </div>
                                        <img src={imgCardBG} class="UT7-card-thumb" />
                                    </a>
                                    </NavLink>
                                </div>
                                
                                <div class="col-xxl-6 col-xl-4 col-lg-6 col-md-6">
                                    <NavLink to="/Bengal-Durga-Puja-2022">
                                    <a class="UT7-gallery-card year">
                                        <div class="UT7-gallery-card-cnt-overlay">
                                            <div class="UT7-gallery-card-cnt">
                                                <div class="UT7-card-title">2022</div>
                                            </div>
                                        </div>
                                        <img src={imgCardBG} class="UT7-card-thumb" />
                                    </a>
                                    </NavLink>
                                    
                                </div>
                                
                                <div class="col-xxl-6 col-xl-4 col-lg-6 col-md-6">
                                    <NavLink to="/Kolkata-Durga-Puja-2023">
                                    <a class="UT7-gallery-card year">
                                        <div class="UT7-gallery-card-cnt-overlay">
                                            <div class="UT7-gallery-card-cnt">
                                                <div class="UT7-card-title">2023<br/>
                                                {/* <small>Coming Soon</small> */}
                                                </div>
                                            </div>
                                        </div>
                                        <img src={imgCardBG} class="UT7-card-thumb" />
                                    </a>
                                    </NavLink>    
                                </div>

                                <div class="col-xxl-6 col-xl-4 col-lg-6 col-md-6">
                                    <NavLink to="/Kolkata-Durga-Puja-2024">
                                    <a class="UT7-gallery-card year">
                                        <div class="UT7-gallery-card-cnt-overlay">
                                            <div class="UT7-gallery-card-cnt">
                                                <div class="UT7-card-title">2024<br/>
                                                {/* <small>Coming Soon</small> */}
                                                </div>
                                            </div>
                                        </div>
                                        <img src={imgCardBG} class="UT7-card-thumb" />
                                    </a>
                                    </NavLink>    
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>

            </div>

        </div>
    </div>
                        
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>)
}




export default GalleryPage;